import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"

import Img from "gatsby-image"

const Tile = styled.div`
  flex-basis: 100%;
  padding: 0rem;
  visibility: hidden;

  :nth-child(n + 2) {
    margin-top: 3rem;
  }

  @media (min-width: 576px) {
    padding: 1rem;
    flex-basis: calc(50% - 2rem);
    margin-top: 2rem;

    :nth-child(n + 2) {
      margin-top: 2rem;
    }
  }

  @media (min-width: 992px) {
    padding: 1rem;
    flex-basis: calc(25% - 2rem);
  }

  a {
    text-decoration: none;
    color: #272727;
  }
`

const StyledImg = styled(Img)`
  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Information = styled.div`
  margin-top: 1.5rem;
  color: #272727;

  h3 {
    font-weight: 300 !important;
  }

  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Date = styled.div`
  margin-top: 0.5rem;
  color: white;

  transition: opacity 0.7s;
  :hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;

  > a:nth-child(2) {
    margin-left: 0.5rem;
  }
`

const Button = styled.a`
  flex-basis: 50%;
  padding: 0 !important;
  text-transform: uppercase;
`

export default props => {
  let data = props.data

  let buttonOneActive = false
  let buttonTwoActive = false

  if (data.buttonOneText && data.buttonOneLink) {
    buttonOneActive = true
  }

  if (data.buttonTwoText && data.buttonTwoLink) {
    buttonTwoActive = true
  }

  return (
    <Tile className="music-tile">
      <Link to={`/${data.uri}`}>
        {data.albumArt[0].imageFile ? (
          <StyledImg
            loading="eager"
            fluid={data.albumArt[0].imageFile.childImageSharp.fluid}
          />
        ) : null}
      </Link>
      <Buttons>
        <Button
          {...(buttonOneActive ? { href: data.buttonOneLink } : {})}
          className={
            buttonOneActive ? "button button-active" : "button button-inactive"
          }
          target="_blank"
        >
          {data.buttonOneText}
        </Button>
        <Button
          {...(buttonTwoActive ? { href: data.buttonTwoLink } : {})}
          className={
            buttonTwoActive ? "button button-active" : "button button-inactive"
          }
          target="_blank"
        >
          {data.buttonTwoText}
        </Button>
      </Buttons>

      <Information>
        <h3>
          <Link to={`/${data.uri}`}>{data.title}</Link>
        </h3>
      </Information>

      <Date className="date">
        <Link to={`/${data.uri}`}>{data.releaseYear}</Link>
      </Date>
    </Tile>
  )
}
