import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { MusicGrid } from "../components/music"

export default props => {
  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  return (
    <Layout>
      <SEO title="Music" />
      <MusicGrid data={props.data.craft.entries} />
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["music"]) {
        ... on Craft_music_release_Entry {
          uri
          title
          slug
          releaseYear
          buttonOneText
          buttonTwoText
          buttonOneLink
          buttonTwoLink
          albumArt {
            ... on Craft_images_Asset {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
