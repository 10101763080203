import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import MusicTile from "./music-tile"

const Spacer = styled.div`
  padding-top: 6rem;
  background-color: #eeede9;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 1.5rem 6rem 1.5rem;
  background-color: #eeede9;

  @media (min-width: 576px) {
    padding: 0 2.5rem 6rem 2.5rem;
  }
`

// const Divider = styled.div`
//   color: white;
//   margin: 3rem 0rem 0 0rem;
//   width: 100%;
//   padding-bottom: 0.5rem;
//   border-bottom: 1px solid #7c7c7c;

//   h2 {
//     font-weight: 300;
//   }

//   @media (min-width: 576px) {
//     margin: 3rem 1rem 0 1rem;
//   }
// `

export default props => {
  let jonsi = props.data

  // .sort((a, b) => b.releaseYear - a.releaseYear)

  // let sigurRos = props.data.filter(item => item.artist === "sigurRos")

  // .sort((a, b) => b.releaseYear - a.releaseYear)

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 0,
      distance: "30px",
    }).reveal(".music-tile")

    return () => {
      ScrollReveal().destroy()
    }
  }, [])

  return (
    <>
      <Spacer />
      <Container>
        {jonsi.map(data => (
          <MusicTile data={data} />
        ))}
        {/* <Divider>
          <h2>Sigur Rós</h2>
        </Divider>
        {sigurRos.map(data => (
          <MusicTile data={data} />
        ))} */}
      </Container>
    </>
  )
}
